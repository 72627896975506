import { Component, forwardRef, Input } from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  styleUrls: ['./checkbox.component.scss'],
  templateUrl: './checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  standalone: true,
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() checked = false;
  @Input() intermediate = false;
  @Input() disabled = false;
  @Input() label = '';
  @Input() description = '';
  @Input() tooltip = '';
  @Input() required = false;

  onChange = (checked: boolean): void => {};
  onTouched = (): void => {};

  writeValue(checked: boolean): void {
    this.checked = checked;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
